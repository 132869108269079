import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { usePanel } from '~/providers/PanelProvider'

const PanelCart = dynamic(() => import('~/components/Panels/CartPanelWrapped'))

export function useCartAutomaticOpening() {
  const { push, ...router } = useRouter()
  const needToOpenCart = router.query?.cart === 'open'

  const { add } = usePanel()
  const openPanel = () => {
    add({
      component: <PanelCart />,
    })
  }

  if (needToOpenCart) {
    // remove search query
    const { cart, ...restQuery } = router.query
    push(
      {
        ...router,
        query: restQuery,
      },
      null,
      { shallow: true },
    ).then(() =>
      // open cart
      openPanel(),
    )
  }
}
